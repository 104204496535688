import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HorizonArt from '../images/horizon-art.png'
import Social from "../components/social-links"
import { Helmet } from "react-helmet"
import EmailForm from "../components/email-form"
import ProfilePic from '../images/naideia-profile-pic.jpg'


const IndexPage = () => (
  
  <Layout>
  <Helmet>
  <script>
      {`
        var cpm = {};
        (function(h,u,b){
        var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
        e.async=true;e.src='https://cookiehub.net/c2/9410b03d.js';
        e.onload=function(){u.cookiehub.load(b);}
        d.parentNode.insertBefore(e,d);
        })(document,window,cpm);
      `}
    </script>
</Helmet>
 
    <SEO title="naideia" />
    <div className="intro-section-inner">
    <div className="about-nav">
    <a className="subnav" href="/about"><h4 id="about-naidieia" className="about-subnav selected">About Naideia</h4></a>
      <h4 className="about-subnav">|</h4>
      <a className="subnav" href="/about-james"><h4 id="about-james" className="about-subnav">About James</h4></a>
    </div>
      <hr />

      <div id="about">
        <img className="naideia-profile-pic" src={ProfilePic}></img>
        <p>
        Naideia is an electronic music project by producer and multi-instrumentalist, James Cole. 
        </p>
        <p>  
        The music of naideia is influenced by a range of electronic genres, primarily drawing inspiration from Drum and Bass, Neurofunk, Leftfield Bass, and Chillhop artists.
        </p>
        <p>  
        With four releases already in 2021 and an extensive backlog of works in progress, expect more tunes to be released in the coming months.
        </p> 
      </div>
       
    </div>
    <EmailForm />
    <footer>
      <Social />
      <p>Naideia is a drum and bass project by multi-instrumentalist and producer, <a href={"https://linktr.ee/jamescole"}>James Cole</a>.</p>
      <p>© James Cole</p>
    </footer> 

  </Layout>
)

export default IndexPage
